.App {
  text-align: center;
}

.App-logo {
  height: 100px;
  margin: 50px auto 10px;
}

.App-launch {
  margin: 150px auto 10px;
  display: inline-block;
  padding: 10px;
  min-width: 200px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  background-color: #00a1ff;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.App-small {
  font-size: 8px;
}

.App-launch:hover {
  background-color: #0981c7;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  /* color: #09d3ac; */
}
